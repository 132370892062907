/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
import React, { useRef, useState } from 'react';

import { ReactComponent as Chat } from 'images/icon/token_chat.svg';
import { ReactComponent as Call } from 'images/icon/call.svg';
import { ReactComponent as Mail } from 'images/icon/mail.svg';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import i18n from '@utils/i18n';
import MyDialog from '@components/MyDialog/NewDialog';
import AlertDialog from '@components/MyDialog/AlertDialog';
import MyTextField from '@components/MyTextField/NewTextField';
import useResponsive from '@hooks/useResponsive';
// import Radio from '@mui/joy/Radio';
import env from '@constants/env';
import { isEmail, isValidKoreanPhoneNumber } from '@utils/boolean';
import consultationInquiryService from '@services/consultationInquiry';
import { formatMoney, isNumber } from '@utils/helpers';
import { useChannelIOApi } from 'react-channel-plugin';
import MySelect from '@components/MySelect/new';
import { MyRadio } from '@components/MyRadio';
import { popUpIds } from '@constants/utils';
import { MyCheckbox } from '@components/MyCheckbox';
import { districts, provinces } from '../enhance';

const maxLengthOf = {
  companyName: 50,
  picName: 50,
  whereFindUsValue: 100,
  enqPurposeValue: 100,
  phone: 20,
  email: 40
};

const contactT = i18n.getFixedT(null, null, 'pages.contactForm');
const g = i18n.getFixedT(null, null, '');
const services = [
  contactT('form.office'),
  contactT('form.hotel'),
  contactT('form.sports'),
  contactT('form.etc')
];

const buttonT = i18n.getFixedT(null, null, 'button');
const lang = localStorage.getItem('i18nextLng')?.toLowerCase() || 'ko';

const ConsultationInquiryForm = (props) => {
  const isSm = useResponsive('down', 1024);
  const { showMessenger } = useChannelIOApi();
  const { closeModal } = props;

  function reportShowPopup(popupType) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: popUpIds[popupType]
    });
    if (env.devEnv === 'development') console.log('adding event:', popupType);
  }
  const requiredFields = () => {
    const initialFields = [
      'service',
      'companyName',
      'picName',
      'phone',
      'email'
    ];
    if (
      inquiryData?.enqPurpose !== 'none' &&
      inquiryData?.enqPurpose === enqPurpose[enqPurpose.length - 1].value
    ) {
      initialFields.push('enqPurposeValue');
    }
    if (
      inquiryData?.whereFindUs !== 'none' &&
      inquiryData?.whereFindUs === wherefindUs[wherefindUs.length - 1].value
    ) {
      initialFields.push('whereFindUsValue');
    }
    return initialFields;
  };
  // Example usage

  const [inquiryData, setInquiryData] = useState({
    service: contactT('form.office'),
    companyName: '',
    picName: '',
    phone: '',
    email: '',
    enqPurpose: 'none',
    whereFindUs: 'none',
    province: 'none',
    district: '',
    country: '대한민국',
    budget: '',
    currency: lang === 'en' ? 'USD' : 'KRW',
    storageYn: 'N',
    teamSize: '',
    languagePreference: lang === 'ko' ? 'Kor' : 'En'
  });
  const [errMsg, setErrMsg] = useState({
    companyName: '',
    picName: '',
    phone: '',
    email: '',
    country: ''
  });

  const formRef = useRef();
  const [accordion, setAccordion] = useState('none');
  const [accept, setAccept] = useState(false);

  const [others, setOthers] = useState({
    enqPurpose: 'none',
    wherefindUs: 'none'
  });

  const [nofityDialog, setNofityDialog] = useState(undefined);
  const [successOpen, setSuccessOpen] = useState(false);

  // useEffect(() => {
  //   console.log('conversion', typeof window.gtag_report_conversion);

  //   loadConversionScript();
  // }, []);

  const enqPurpose = [
    {
      value: 1,
      sendValue: '임직원 복지',
      label: contactT('form.selectOptions.enqPurpose.1')
    },
    {
      value: 2,
      sendValue: '직원 편의성',
      label: contactT('form.selectOptions.enqPurpose.2')
    },
    {
      value: 3,
      sendValue: '소비자 편의 제공',
      label: contactT('form.selectOptions.enqPurpose.3')
    },
    {
      value: 4,
      sendValue: '수익 창출',
      label: contactT('form.selectOptions.enqPurpose.4')
    },
    {
      value: 5,
      sendValue: '업무 제휴',
      label: contactT('form.selectOptions.enqPurpose.5')
    },
    { value: 6, sendValue: '키타', label: contactT('form.etc') }
  ];
  const wherefindUs = [
    {
      value: 1,
      sendValue: '네이버',
      label: contactT('form.selectOptions.whereFindUs.1')
    },
    {
      value: 2,
      sendValue: '구글',
      label: contactT('form.selectOptions.whereFindUs.2')
    },
    {
      value: 3,
      sendValue: 'SNS 광고(메타, 인스타그램 등)',
      label: contactT('form.selectOptions.whereFindUs.3')
    },
    {
      value: 4,
      sendValue: '신문 기사',
      label: contactT('form.selectOptions.whereFindUs.4')
    },
    {
      value: 5,
      sendValue: '기존 고객 또는 지인 추천',
      label: contactT('form.selectOptions.whereFindUs.5')
    },
    {
      value: 6,
      sendValue: '워커스하이 대표/임직원 소개',
      label: contactT('form.selectOptions.whereFindUs.6')
    },
    { value: 7, sendValue: '키타', label: contactT('form.etc') }
  ];

  const handleSelectChange = (name, value) => {
    console.log(`select data: name: ${name}, value: ${value}`);
    setOthers({ ...others, [name]: value });
    setInquiryData({ ...inquiryData, [name]: value });
    if (
      ['enqPurposeValue', 'whereFindUsValue'].includes(name) &&
      (!value || isNumber(value))
    ) {
      setErrMsg({
        ...errMsg,
        [name]: contactT('form.othersErrMsg')
      });
    } else if (value?.length > maxLengthOf?.[name]) {
      setErrMsg({
        ...errMsg,
        [name]: contactT(`form.${name}-maxlength-error`)
      });
    } else {
      setErrMsg({ ...errMsg, [name]: '' });
    }
  };

  const handleChange = (name, value) => {
    setInquiryData({
      ...inquiryData,
      [name]: value
    });
    if (requiredFields().includes(name)) {
      setErrMsg((prev) => ({
        ...prev,
        [name]: value ? '' : g('common.required')
      }));
      if (value)
        if (Object.keys(maxLengthOf).includes(name)) {
          setErrMsg({
            ...errMsg,
            [name]:
              value?.length > maxLengthOf[name]
                ? contactT(`form.${name}-maxlength-error`)
                : ''
          });
        }
    }
  };

  const handleCheckAccept = (e) => {
    setAccept(e.target.checked);
  };
  const scrollToRow = () => {
    const container = isSm
      ? document.getElementById('paper-container')
      : document.getElementById('form-container');

    if (container) {
      container.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  const handleValidateForm = async (e) => {
    e.preventDefault();
    // eslint-disable-next-line prefer-const
    let isValid = true;
    const err = {};
    requiredFields().forEach((field) => {
      if (!inquiryData[field] || inquiryData[field]?.length < 1) {
        isValid = false;
        err[field] = g('common.required');
      } else if (
        field === 'phone' &&
        !isValidKoreanPhoneNumber(inquiryData[field])
      ) {
        isValid = false;
        err[field] = g('pages.career.error.phone-invalid');
      } else if (field === 'email' && !isEmail(inquiryData[field])) {
        isValid = false;
        err[field] = g('pages.career.error.email-invalid');
      }
    });
    Object.values(errMsg).forEach((error) => {
      if (error) {
        isValid = false;
      }
    });
    // eslint-disable-next-line prefer-rest-params

    if (isValid) {
      await handleCreateInquiry();
    } else {
      scrollToRow();

      setErrMsg({ ...err });
    }
  };

  const checkValidation = () => {
    // eslint-disable-next-line prefer-const
    let isValid = true;

    requiredFields().forEach((field) => {
      if (!inquiryData[field]) {
        isValid = false;
      } else if (errMsg[field]) isValid = false;
    });

    return isValid;
  };

  const handleCreateInquiry = async () => {
    const dataSubmit = {
      service: inquiryData.service,
      companyName: inquiryData.companyName,
      picName: inquiryData.picName,
      phone: inquiryData.phone,
      email: inquiryData.email,
      enqPurpose: (() => {
        const enqPurposeValue = enqPurpose.find(
          (item) => item.value === others.enqPurpose
        );
        return enqPurposeValue?.value === 6
          ? `기타 --> ${others?.enqPurposeValue}`
          : enqPurposeValue?.sendValue;
      })(),
      inflowPath: (() => {
        const wherefindUsValue = wherefindUs.find(
          (item) => item.value === others.whereFindUs
        );
        return wherefindUsValue?.value === 7
          ? `기타 --> ${others?.whereFindUsValue}`
          : wherefindUsValue?.sendValue;
      })(),
      country:
        lang === 'en' ? inquiryData?.country?.name : inquiryData?.country,
      province: (() => {
        const province = provinces.find(
          (item) => item?.value === inquiryData?.province
        );
        return province ? province.sendValue : '';
      })(),
      district: (() => {
        const district = inquiryData?.province
          ? districts[inquiryData?.province]?.find(
              (item) => item?.value === inquiryData?.district
            )
          : null;
        return district?.sendValue || inquiryData?.district;
      })(),
      budget: inquiryData?.budget,
      currency: inquiryData?.currency,
      storageYn: inquiryData?.storageYn,
      teamSize: inquiryData?.teamSize,
      languagePreference: inquiryData?.languagePreference
    };

    const res = await consultationInquiryService.createInquiry(dataSubmit);
    if (!res.error) {
      setSuccessOpen(true);
      reportShowPopup('CONSULTATION');
    } else {
      setNofityDialog({
        title: res.error?.message
      });
    }
  };

  return (
    <Box
      sx={{ display: 'flex', width: '100%', flexDirection: 'column', gap: 3.5 }}
    >
      {/* service */}
      <Stack sx={{}}>
        <Stack sx={{ display: ' flex', flexDirection: 'row', gap: 0.5 }}>
          <Typography
            sx={{
              fontSize: { md: 14, xs: 14 },
              fontWeight: 600,
              color: '#212121'
            }}
          >
            {contactT('form.selectLabel')}
          </Typography>
          <Stack
            sx={{
              backgroundColor: '#FF6565',
              width: '4px',
              height: '4px',
              borderRadius: '50%'
            }}
          />
        </Stack>

        <Stack
          direction="row"
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            gap: 1.5,
            mt: { md: '12px', xs: '12px' }
          }}
        >
          {services.map((item) => (
            <Stack
              onClick={() => setInquiryData({ ...inquiryData, service: item })}
              key={`${item}+`}
              sx={{
                minWidth: { md: '65px', xs: '53px' },
                height: { md: '40px', xs: '36px' },
                p: '10px 14px 10px 14px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: { md: '20px !important', xs: '20px !important' },
                fontSize: { md: 14, xs: 14 },
                fontWeight: 500,
                bgcolor:
                  inquiryData.service === item ? 'secondary.main' : '#FFFFFF',
                color: inquiryData.service === item ? '#FFFFFF' : '#919193',
                outline: inquiryData.service === item ? 0 : '1px solid #DEE2E6',

                '&:hover': {
                  cursor: 'pointer',
                  bgcolor: 'secondary.main',
                  color: '#FFF'
                }
              }}
            >
              {item}
            </Stack>
          ))}
        </Stack>
      </Stack>

      {/* companyName */}
      <Stack
        sx={{
          width: '100%',
          flexDirection: 'column',
          gap: 1
        }}
      >
        <MyTextField
          required
          fullWidth
          label={contactT('form.companyName')}
          placeholder={contactT('form.companyPlaceholder')}
          errMg={errMsg.companyName}
          value={inquiryData.companyName}
          onChange={(e) => handleChange('companyName', e.target.value)}
          onClear={() => handleChange('companyName', '')}
        />
        {errMsg.companyName && (
          <Typography
            sx={{
              fontSize: { md: 13, xs: 12 },
              color: '#FF6565'
            }}
          >
            {errMsg.companyName}
          </Typography>
        )}
      </Stack>

      {/* picName */}
      <Stack
        sx={{
          width: '100%',
          flexDirection: 'column',
          gap: 1
        }}
      >
        <MyTextField
          required
          fullWidth
          errMg={errMsg.picName}
          label={contactT('form.pic')}
          placeholder={contactT('form.picPlaceholder')}
          value={inquiryData.picName}
          onChange={(e) => handleChange('picName', e.target.value)}
          onClear={() => handleChange('picName', '')}
        />
        {errMsg.picName && (
          <Typography
            sx={{
              fontSize: { md: 13, xs: 12 },
              color: '#FF6565'
            }}
          >
            {errMsg.picName}
          </Typography>
        )}
      </Stack>

      {/* phone */}
      <Stack
        sx={{
          width: '100%',
          flexDirection: 'column',
          gap: 1
        }}
      >
        <MyTextField
          required
          fullWidth
          label={contactT('form.phoneNumber')}
          placeholder={contactT('form.contactPlaceholder')}
          value={inquiryData.phone}
          // onChange={(e) => handleChange('phone', e.target.value)}
          onChange={(e) => {
            handleChange('phone', e.target.value.replace(/\D/g, ''));
          }}
          onClear={() => handleChange('phone', '')}
          // onKeyDown={onlyNumber}
          errMg={errMsg.phone}
        />
        {errMsg.phone && (
          <Typography
            sx={{
              fontSize: { md: 13, xs: 12 },
              color: '#FF6565'
            }}
          >
            {errMsg.phone}
          </Typography>
        )}
      </Stack>

      {/* Email */}
      <Stack
        sx={{
          width: '100%',
          flexDirection: 'column',
          gap: 1
        }}
      >
        <MyTextField
          required
          errMg={errMsg.email}
          fullWidth
          label={contactT('form.email')}
          placeholder={contactT('form.emailPlaceholder')}
          value={inquiryData.email}
          onChange={(e) => handleChange('email', e.target.value)}
          onClear={() => handleChange('email', '')}
        />
        {errMsg.email && (
          <Typography
            sx={{
              fontSize: { md: 13, xs: 12 },
              color: '#FF6565'
            }}
          >
            {errMsg.email}
          </Typography>
        )}
      </Stack>

      {/* dropdowns */}
      <Stack
        sx={{
          width: '100%',
          flexDirection: { md: 'row', xs: 'column' },
          gap: { md: 2.5, xs: 2.5 }
        }}
      >
        <Stack
          sx={{
            display: 'flex',
            width: { md: '50%', xs: '100%' },
            flexDirection: { md: 'column', xs: 'column' },
            gap: { md: 2.5, xs: 1.5 }
          }}
        >
          <MySelect
            label={contactT('form.enquiryPurpose')}
            placeholder={contactT('form.enquiryPurpose-placeholder')}
            data={enqPurpose}
            onChange={(e) => {
              handleSelectChange('enqPurpose', e.target.value);
            }}
            value={inquiryData?.enqPurpose}
            fullWidth
          />

          <Stack
            display={others.enqPurpose === 6 ? 'flex' : 'none'}
            sx={{
              flexDirection: 'column',
              gap: 0.5
            }}
          >
            <MyTextField
              fullWidth
              placeholder={contactT('form.othersFieldPlaceHolder')}
              value={others?.enqPurposeValue}
              required
              errMg={errMsg.enqPurposeValue}
              onChange={(e) =>
                handleSelectChange('enqPurposeValue', e.target.value)
              }
              onClear={() => setOthers({ ...others, enqPurposeValue: '' })}
            />
            {errMsg.enqPurposeValue && (
              <Typography
                sx={{
                  fontSize: { md: 13, xs: 12 },
                  color: '#FF6565'
                }}
              >
                {errMsg.enqPurposeValue}
              </Typography>
            )}
          </Stack>
        </Stack>

        <Stack
          sx={{
            flexDirection: { md: 'column', xs: 'column' },
            gap: { md: 2.5, xs: 1.5 },
            width: { md: '50%', xs: '100%' }
          }}
        >
          <MySelect
            label={contactT('form.whereFindUs')}
            placeholder={contactT('form.whereFindUs-placeholder')}
            data={wherefindUs}
            onChange={(e) => {
              handleSelectChange('whereFindUs', e.target.value);
            }}
          />

          <Stack
            display={others.whereFindUs === 7 ? 'flex' : 'none'}
            sx={{
              flexDirection: 'column'
            }}
          >
            <Stack
              sx={{
                flexDirection: 'column',
                alignItems: 'start',
                gap: 0.5
              }}
            >
              <MyTextField
                fullWidth
                placeholder={contactT('form.othersFieldPlaceHolder')}
                value={others?.whereFindUsValue}
                required
                errMg={errMsg.whereFindUsValue}
                onChange={(e) =>
                  handleSelectChange('whereFindUsValue', e.target.value)
                }
                onClear={() => setOthers({ ...others, whereFindUsValue: '' })}
              />
              {errMsg.whereFindUsValue && (
                <Typography
                  sx={{
                    fontSize: { md: 13, xs: 12 },
                    color: '#FF6565'
                  }}
                >
                  {errMsg.whereFindUsValue}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      {/* country and additional info */}
      <Stack
        sx={{
          width: '100%',
          flexDirection: 'column',
          gap: 1.5
        }}
      >
        <Typography
          sx={{
            fontSize: { md: lang === 'ko' ? 14 : 12, xs: 14 },
            color: '#212121',
            fontWeight: 600,
            width: lang === 'ko' ? '100px' : '130px',
            display: 'flex',
            flexDirection: 'row',
            gap: 0.5,
            whiteSpace: lang === 'ko' ? 'noWrap' : 'wrap'
          }}
        >
          {contactT('form.location')}
        </Typography>

        <Stack
          sx={{
            flexDirection: { md: 'row', xs: 'column' },
            width: '100%',
            gap: 2.5
          }}
        >
          <Stack
            sx={{
              width: { md: '50%', xs: '100%' }
            }}
          >
            {/* province */}
            <MySelect
              fullWidth
              data={provinces}
              placeholder={contactT('form.province-placeholder')}
              onChange={(e) => {
                handleSelectChange('province', e.target.value);
              }}
              MenuProps={{
                sx: {
                  '& .Mui-selected': {
                    color: '#4267EB',
                    backgroundColor: '#FFFFFF',
                    fontWeight: 500,
                    fontSize: 16,
                    whiteSpace: 'nowrap'
                  }
                },
                PaperProps: {
                  sx: {
                    maxHeight: 200,
                    borderRadius: 0
                  }
                }
              }}
              menuPaper={{
                maxHeight: 100
              }}
            />
            {/* district */}
          </Stack>
          <Stack
            sx={{
              width: { md: '50%', xs: '100%' }
            }}
          >
            <MySelect
              fullWidth
              data={districts[inquiryData?.province]}
              placeholder={contactT('form.district-placeholder')}
              onChange={(e) => {
                handleSelectChange('district', e.target.value);
              }}
              hideMenuItems={inquiryData.province === 'none'}
              MenuProps={{
                sx: {
                  '& .Mui-selected': {
                    color: '#4267EB',
                    backgroundColor: '#FFFFFF',
                    fontWeight: 500,
                    fontSize: 16,
                    whiteSpace: 'nowrap'
                  }
                },
                PaperProps: {
                  sx: {
                    maxHeight: 200,
                    borderRadius: 0
                  }
                }
              }}
            />
          </Stack>
        </Stack>
      </Stack>

      {/* Budget and Employee */}
      <Stack
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: { md: 'row', xs: 'column' },
          gap: { md: 2.5, xs: 3.5 }
        }}
      >
        <Stack
          sx={{
            flexDirection: 'column',
            width: { md: '50%', xs: '100%' }
          }}
        >
          <MyTextField
            fullWidth
            errMg={errMsg.budget}
            label={contactT('form.budget')}
            placeholder={contactT('form.budget-placeholder')}
            value={formatMoney(inquiryData.budget)}
            onChange={(e) => handleChange('budget', e.target.value)}
            typeValue="onlyNumber"
            endComponent={
              <Typography
                sx={{
                  color: '#67686C',
                  whiteSpace: 'nowrap',
                  fontSize: { md: 14, xs: 12 }
                }}
              >
                {contactT('form.money')}
              </Typography>
            }
            onClear={() => handleChange('budget', '')}
          />
        </Stack>
        <Stack
          sx={{
            flexDirection: 'column',

            width: { md: '50%', xs: '100%' }
          }}
        >
          <MyTextField
            errMg={errMsg.teamSize}
            label={contactT('form.teamSize')}
            placeholder={contactT('form.teamsize-placeholder')}
            value={formatMoney(inquiryData.teamSize)}
            onChange={(e) => handleChange('teamSize', e.target.value)}
            onClear={() => handleChange('teamSize', '')}
            typeValue="onlyNumber"
            endComponent={
              <Typography
                sx={{
                  color: '#67686C',
                  whiteSpace: 'nowrap',
                  fontWeight: 500,
                  fontSize: { md: 14, xs: 12 }
                }}
              >
                {contactT('form.people')}
              </Typography>
            }
            fullWidth
          />
        </Stack>
      </Stack>

      {/* Storage availabilty */}
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'baseline',
          width: { md: '100%', xs: '100%' },
          gap: 1.5
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            flexDirection: 'row',
            color: '#212121',
            fontSize: { md: lang === 'ko' ? 14 : 14, xs: 14 },
            fontWeight: 600
          }}
        >
          {contactT('form.storageYn')}
        </Typography>
        <Stack
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            gap: 3,
            alignItems: 'center'

            // justifyContent: 'flex-start'
          }}
        >
          <FormControl sx={{ ml: { md: 1.5 } }}>
            <FormControlLabel
              label={contactT('form.available')}
              labelPlacement="end"
              sx={{
                '.MuiTypography-root': {
                  color: '#212121',
                  fontWeight: 600,
                  fontSize: 14,
                  whiteSpace: 'nowrap',
                  ml: 1.5
                }
              }}
              control={
                <MyRadio
                  checked={inquiryData?.storageYn === 'Y'}
                  onChange={() =>
                    handleChange(
                      'storageYn',
                      inquiryData?.storageYn === 'Y' ? 'N' : 'Y'
                    )
                  }
                />
              }
            />
          </FormControl>
          <FormControl sx={{ ml: { md: 1 } }}>
            <FormControlLabel
              label={contactT('form.unavailable')}
              labelPlacement="end"
              sx={{
                '.MuiTypography-root': {
                  color: '#212121',
                  fontWeight: 600,
                  fontSize: 14,
                  whiteSpace: 'nowrap',
                  ml: 1
                }
              }}
              control={
                <MyRadio
                  checked={inquiryData?.storageYn === 'N'}
                  onChange={() =>
                    handleChange(
                      'storageYn',
                      inquiryData?.storageYn === 'Y' ? 'N' : 'Y'
                    )
                  }
                />
              }
            />
          </FormControl>
        </Stack>
      </Stack>

      {/* Terms of service */}
      <Stack
        sx={{
          width: '100%'
        }}
      >
        <Stack
          sx={{
            flexDirection: { md: 'row', xs: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Stack
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            <MyCheckbox
              checked={accept}
              onChange={handleCheckAccept}
              sx={{
                '&.MuiCheckbox-root': {
                  // outline: '1px solid #DEE2E6',
                },
                // width: { md: '20px', xs: '20px' },
                // height: { md: '20px', xs: '20px' },
                mr: { md: '12px' },
                ml: -1
              }}
            />
            <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
              <Typography
                sx={{
                  fontSize: { md: lang === 'ko' ? 14 : 12, xs: 14 },
                  color: '#212121',
                  fontWeight: 600,

                  whiteSpace: 'noWrap'
                }}
              >
                {contactT('form.agreeLabel')}
              </Typography>
              <Stack
                sx={{
                  backgroundColor: '#FF6565',
                  width: '4px',
                  height: '4px',
                  borderRadius: '50%'
                }}
              />
            </Stack>
          </Stack>
          <Stack
            onClick={() => setAccordion(accordion === 'none' ? true : 'none')}
            sx={{
              flexDirection: 'row',
              color: '#92939A',
              fontSize: { md: 14, xs: 14 },
              fontWeight: 500,
              textDecoration: 'underline',
              alignItems: 'center',
              mt: { md: 0, xs: '0px' },
              '&:hover': {
                cursor: 'pointer'
              }
            }}
          >
            {accordion === 'none'
              ? contactT('form.detail-open')
              : contactT('form.detail-close')}
          </Stack>
        </Stack>
        <Stack
          display={accordion}
          sx={{
            fontSize: { md: 12, xs: 12, color: '#92939A' },
            width: '100%',
            pr: '24px',
            mt: '30px',
            ml: '28px',
            whiteSpace: 'pre-line'
          }}
        >
          {contactT('form.privacyPolicy')}
        </Stack>
      </Stack>
      {/* Action Buttons */}
      <Stack
        sx={{
          width: '100%',
          mt: { md: '12px' }
        }}
      >
        <Button
          variant="contained"
          disabled={!accept || !checkValidation()}
          onClick={(e) => handleValidateForm(e)}
          sx={{
            height: { md: 40, xs: 40 },
            width: { md: '100%', xs: '100%' },
            fontSize: { md: 16, xs: 12 },
            backgroundColor: '#244EE2',
            color: '#FFFFFF',
            p: '10px 16px',
            fontWeight: 500,
            ':hover': {
              backgroundColor: { md: '#1D3FB7', xs: '#244EE2' },
              color: '#FFFFFF'
            },
            borderRadius: 0,
            '&.Mui-disabled': {
              backgroundColor: '#B2BEEB',
              color: '#FFFFFF'
            }
          }}
        >
          {contactT('form.button.CONSULTATION')}
        </Button>
      </Stack>

      {/* info section */}
      <Stack
        sx={{
          width: '100%',
          mt: 0.5,
          py: '20px',
          borderTop: '1px solid #DEE2E6',
          borderBottom: '1px solid #DEE2E6'
        }}
      >
        <Grid container rowSpacing={3.5}>
          {[
            {
              icon: <Chat />,
              text: '1:1 채팅하기',
              action: () => showMessenger()
            },
            { icon: <Call />, text: '02-2038-3679', href: 'tel:02-2038-3679' },
            {
              icon: <Mail />,
              text: 'service@workershigh.com',
              href: 'mailto:service@workershigh.com'
            }
          ].map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={`${item.text}-${index}`}>
              <Stack
                component={item.href ? 'a' : 'div'}
                href={item.href}
                onClick={item.action}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  height: '100%',
                  gap: 1,
                  alignItems: 'center',
                  justifyContent: { md: 'center', xs: 'center' },
                  cursor: 'pointer',
                  textDecoration: 'none', // ensure no default underline for links
                  '&:hover .typography-underline': {
                    textDecoration: 'underline'
                  }
                }}
              >
                {item?.icon}

                <Typography
                  className="typography-underline"
                  sx={{
                    fontSize: { md: 14 },
                    fontWeight: 500,
                    whiteSpace: 'nowrap',
                    letterSpacing: '-0.42px',
                    fontFamily: 'Pretendard',
                    color: '#919193',
                    position: 'relative'
                  }}
                >
                  {item?.text}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Stack>
      {/* Notify Dialog */}
      {nofityDialog && (
        <MyDialog
          isAlert
          open={nofityDialog}
          setOpen={() => setNofityDialog(false)}
          title={nofityDialog?.title}
          content={nofityDialog?.content}
          onOk={nofityDialog?.onOK}
          onClose={nofityDialog?.onClose}
          hasCancelButton={nofityDialog?.hasCancelButton}
          {...nofityDialog}
        />
      )}
      {successOpen && (
        <AlertDialog
          open={successOpen}
          sx={{ minWidth: '335px' }}
          isAlert
          buttonId={popUpIds.CONSULTATION}
          disableBackdropClick
          title={contactT('form.success.consultation-success-title')}
          subTitle={contactT('form.success.consultation-success-subtitle')}
          setOpen={() => setSuccessOpen(false)}
          onOk={() => {
            closeModal();
            setTimeout(() => {
              setSuccessOpen(false);
            }, 100);
          }}
        />
      )}
    </Box>
  );
};

export default ConsultationInquiryForm;
