import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

// import { ReactChannelIO } from 'react-channel-plugin';
import Globals from '_Globals';
import App from './App';
import store from './store';
import ThemeConfig from './theme';
import reportWebVitals from './reportWebVitals';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
const AppRender = () => {
  return (
    <>
      <App />
      <Globals />
    </>
  );
};
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <ThemeConfig>
          <AppRender />
        </ThemeConfig>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
